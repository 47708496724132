// import message from '../message';

export const handleFetch = (resolve, reject) => {
  return {
    xthen: (response) => {
      if (!response) {
        return;
      }

      const contentType = response.headers['content-type'];
      const contentDisp = response.headers['content-disposition'];
      const isJson = /(application|text)\/json/.test(contentType);
      const isAttachment = /attachment/.test(contentDisp);
      const isPlainText = /text\/plain/.test(contentType);

      if (!response.status === '200') {
        if (isJson) {
          throw response.json();
        }
        // throw Error(message.unknown_response);
        throw Error('message unknown');
      }

      if (isAttachment) {
        response.blob().then((blob) => {
          resolve(blob);
        });
        return;
      }
      if (isJson || isPlainText || response.data instanceof Blob) {
        resolve(response.data);
      }

      if (response.status === 204) {
        // throw Error(message.unknown_response);
        throw Error('message unknown');
      }

      resolve(true);
    },
    xsthen: (response) => {
      resolve(response);
    },
    xcatch: (errorResponse) => {
      // is thrown json

      if (errorResponse && errorResponse.then) {
        errorResponse.then((errJson) => {
          return reject(errJson.errorMsg || JSON.stringify(errJson));
        });
      } else if (errorResponse.response && errorResponse.response.data) {
        if (errorResponse.response.data.status !== 400) {
          return reject(errorResponse.response.data);
        }
        // return reject(message.unknown_response);
        return reject('Message uknown');
      } else {
        // return reject(message.unknown_response);
        return reject('Message uknown');
      }
      return true;
    },
  };
};
