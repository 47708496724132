import {
  SET_PAGE,
  SET_FILES_COUNT,
  SET_DIRECTORIES_COUNT,
  SET_PATH,
  ENTER_TO_DIRECTORY,
  SET_FILE_LIST,
  SET_FILE_LIST_FILTER,
  SET_LOADING,
  SET_VISIBLE_DIALOG_CONTENT,
  SET_VISIBLE_DIALOG_CROPPER,
  SET_FILE_CONTENT_IMAGE_URL,
  SET_FILE_CROPPER_IMAGE,
  SET_SELECTED_FILES,
  TOGGLE_SELECTED_FILE,
  SET_CONTEXT_MENU_VISIBLE,
  SET_CONTEXT_MENU_POSITION,
  SET_VISIBLE_DIALOG_CREATE_FOLDER,
  SET_VISIBLE_DIALOG_UPLOAD_FILE,
  SET_FILE_UPLOAD_LIST,
  SET_FILE_UPLOAD_PROGRESS,
  ENTER_TO_DIRECTORY_SEARCH,
  SET_SEARCH_PATH,
} from '@actions/fm.action';

export const defaultState = {
  page: 1,
  directoriesCount: null,
  filesCount: null,
  limit: 15,
  folderLimit: 5,
  path: [],
  searchPath: '',
  fileList: [],
  fileListFilter: null,
  selectedFiles: [],
  loading: false,
  visibleDialogContent: false,
  visibleDialogCropper: false,
  visibleDialogCreateFolder: false,
  visibleDialogUploadFile: false,
  fileContentImageUrl: null,
  fileCropperImage: {},
  fileUploadProgress: 0,
  fileUploadList: [],
};

const MainReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_PAGE:
      return { ...state, page: action.value };

    case SET_FILES_COUNT:
      return { ...state, filesCount: action.value };

    case SET_DIRECTORIES_COUNT:
      return { ...state, directoriesCount: action.value };

    case SET_PATH:
      return { ...state, path: action.value };
    case SET_SEARCH_PATH:
      return { ...state, searchPath: action.value };

    case ENTER_TO_DIRECTORY:
      return { ...state, path: [...action.value] };
    case ENTER_TO_DIRECTORY_SEARCH:
      return { ...state, path: [state.searchPath, action.value] };

    case SET_FILE_LIST:
      return {
        ...state,
        fileList: action.value.sort((a, b) =>
          a.type < b.type ? -1 : a.name.toLowerCase() > b.name.toLowerCase()
        ),
      };

    case SET_FILE_LIST_FILTER:
      return { ...state, fileListFilter: action.value };

    case SET_LOADING:
      return { ...state, loading: action.value };

    case SET_VISIBLE_DIALOG_CONTENT:
      return { ...state, visibleDialogContent: !!action.value };

    case SET_VISIBLE_DIALOG_CROPPER:
      return { ...state, visibleDialogCropper: !!action.value };

    case SET_VISIBLE_DIALOG_CREATE_FOLDER:
      return { ...state, visibleDialogCreateFolder: !!action.value };

    case SET_VISIBLE_DIALOG_UPLOAD_FILE:
      return { ...state, visibleDialogUploadFile: !!action.value };

    case SET_FILE_CONTENT_IMAGE_URL:
      return { ...state, fileContentImageUrl: action.value };

    case SET_FILE_CROPPER_IMAGE:
      return { ...state, fileCropperImage: action.value };

    case SET_FILE_UPLOAD_LIST:
      return { ...state, fileUploadList: action.value };

    case SET_FILE_UPLOAD_PROGRESS:
      return { ...state, fileUploadProgress: Number(action.value || 0) };

    case SET_SELECTED_FILES:
      return {
        ...state,
        selectedFiles: action.value.filter(
          (f, i, self) => self.map((ff) => ff.name).indexOf(f.name) === i
        ),
      };

    case TOGGLE_SELECTED_FILE:
      return {
        ...state,
        selectedFiles: state.selectedFiles.find(
          (f) => f.name === action.value.name
        )
          ? state.selectedFiles.filter((f) => f.name !== action.value.name)
          : [...state.selectedFiles, action.value],
      };

    case SET_CONTEXT_MENU_VISIBLE:
      return { ...state, contextMenuVisible: action.value };

    case SET_CONTEXT_MENU_POSITION:
      return { ...state, contextMenuPosition: action.value };
    default:
      return state;
  }
};

export default MainReducer;
