// import { firstLetterToUpperCase } from '@utility/base';
import message from './message';

const include = (x) => (y) => x.includes(y);
const handleMessages = (err, spec) => {
  if (!err && !err.key) return;
  const keyword = message[err.key] ? message[err.key] : '';
  const keyValues = [];

  err.value.map((val) => {
    const parsedVal = val.replace(/[^a-zA-Z]/g, '');

    if (err.key === 'number' && val === 'exist') {
      keyValues.push(keyword[spec + parsedVal]);
      return;
    }
    console.log(keyword[parsedVal]);

    if (keyword[parsedVal]) {
      keyValues.push(keyword[parsedVal]);
    }
  });
  if (keyValues && keyValues.length > 0) return keyValues;

  if (err.value) return `${err.key} -> ${err.value.toString()}`;

  return message.DEFAULT;
};
const generateErrorMsg = (err, spec) => {
  if (Array.isArray(err)) {
    const errors = [];

    err.forEach((error) => {
      const txt = handleMessages(error, spec);
      errors.push(txt);
    });

    return errors;
  }
  return handleMessages(err);
};

export default generateErrorMsg;
