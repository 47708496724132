export default {
  FILE_MANAGER: `${process.env.API_URL}/fm`,
  MEDIA: `${process.env.API_URL}/m`,
  MEDIA_HUB: `${process.env.API_URL}/m`,
  CONTENT: `${process.env.API_URL}/ct`,
  PAGE: `${process.env.API_URL}/pg`,
  NOTIFICATIONS: `${process.env.API_URL}/ntf`,
  USERS: `${process.env.API_URL}/u`,
  I18N: `${process.env.API_URL}/ct/locales`,
  CONSUMERS: `${process.env.API_URL}/cs`,
  GEORGIAN_MONEY: `${process.env.API_URL}/gm`,
  EVENT: `${process.env.API_URL}/e`,
};
