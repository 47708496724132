// ** Reducers Imports
import users from '@src/views/App/Users/User/store';
import baseReducer from '@src/store/reducers/base.reducer';
import fmReducer from '@src/store/reducers/fm.reducer';
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';

const rootReducer = {
  auth,
  navbar,
  layout,
  users,
  base: baseReducer,
  fm: fmReducer,
};

export default rootReducer;
