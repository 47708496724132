import React, { createContext, useState, useContext } from "react";

export const StoreContext = createContext({});

export const StoreContextProvider = ({ children }) => {
  const [role, setRole] = useState("");

  return (
    <StoreContext.Provider
      value={{
        role,
        setRole,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

const useStore = () => useContext(StoreContext);

export default useStore;
