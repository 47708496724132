import {
  ENQUEUE_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from "@actions/base.action";

const initialState = {
  notifications: [],
};

const baseReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENQUEUE_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.value,
          },
        ],
      };

    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.value
        ),
      };

    default: {
      return state;
    }
  }
};

export default baseReducer;
