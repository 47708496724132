// import { axiosRemote as axios } from '@utils/axios';
import axios from 'axios';
import config from './config.js';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import generateErrorMsg from '@utility/generateErrorMsg';

const pathQueryParameter = (path) =>
  path.length > 0 ? `path=${encodeURIComponent(path)}` : '';
const buildQuery = (...args) => args.filter((x) => x).join('&');
const skipQueryParameter = (page, limit) =>
  page > 1 ? `skip=${page * limit - limit}` : '';

export const list = ({ path, page, limit, searchWord = '' }) => {
  const query = buildQuery(
    pathQueryParameter(path),
    skipQueryParameter(page, limit),
    `take=${limit}`,
    `q=${searchWord}`,
    'orderBy=ABCAsc'
  );
  return axios.get(`${config.url_list}?${query}`);
};

export const directoryList = ({ path, page, limit, searchWord = '' }) => {
  const query = buildQuery(
    pathQueryParameter(path),
    skipQueryParameter(page, limit),
    `take=${limit}`,
    `q=${searchWord}`,
    'orderBy=ABCAsc'
  );
  return axios.get(`${config.url_directories}?${query}`);
};

export const createFolder = ({ path, folderName }) => {
  return axios.post(
    `${config.url_directories}?${pathQueryParameter(
      path.concat(path ? '/' : '', folderName)
    )}`
  );
};

export const uploadFiles = ({ path, fileList }) => {
  return fileList.reduce((acc, val) => {
    const fd = new FormData();
    fd.append('file', val);
    return [
      ...acc,
      new Promise((res, rej) => {
        return axios
          .post(`${config.url_list}?${pathQueryParameter(path)}`, fd)
          .then(res)
          .catch((e) => {
            const err = { e };
            if (err.e.message) {
              const result = generateErrorMsg(e.response.data.errors);
              NotificationManager.error(result, '', 3000);
            }

            return { err };
          });
      }),
    ];
  }, []);
};

export const cropImage = ({ path, options }) => {
  const payload = {
    imagePath: path.concat(path ? '/' : '', options.name),
    imageUploadPath: path,
    x: Math.round(options.x),
    y: Math.round(options.y),
    width: Math.round(options.width),
    height: Math.round(options.height),
    rotate: options.rotate,
  };

  return axios.post(`${config.url_image}/crop`, payload);
};

export const deleteFilesAndDirs = ({ path, list }) => {
  return list.reduce((acc, val) => {
    const endpoint =
      val.type === 'dir' ? config.url_directories_delete : config.url_list;
    return [
      ...acc,
      axios.delete(
        `${endpoint}?${pathQueryParameter(
          path.concat(path ? '/' : '', val.name)
        )}`
      ),
    ];
  }, []);
};
