export default {
  DEFAULT: 'დაფიქსირდა შეცდომა',
  unknown_response: 'Unknown error response from connector',

  user: {
    islockedout:
      'User access has been restricted for limited time due to too many login attempts',
    loginfailed: 'Incorrect password',
    notallowed: 'User access is not allowed',
  },
  identifier: {
    notvalid: 'Invalid email',
    notexist: "User with such Identifier doesn't exist",
    loginfailed: 'Incorrect password',
  },
  password: {
    notvalid: 'Invalid password, please enter password using requirements',
  },
  phoneNumber: {
    exist: "The phone number you've entered is already in use",
  },
  email: {
    notvalid:
      "The email you've entered is invalid, please enter the correct mail",
    exist: "The email you've entered is already in use",
    required: 'The Email field is required',
  },
  invalidToken: {
    Invalidtoken: 'Token has expired, please resend reset link',
  },
  userId: {
    emailisnotconfirmed:
      "You can't change users role whose email hasn't been confirmed",
  },
  id: {
    thiscityhasdistricts:
      'This city cannot be deleted because it has districts attached to it',
    thiscityhasprojects:
      'This city cannot be deleted because it has projects attached to it',
    thisdistricthasprojects:
      'This district cannot be deleted because it has projects attached to it',
    thiscategoryhasnews:
      'This category cannot be deleted because it is attached to news',
    thiscategoryhaspartners:
      'This category cannot be deleted because it is attached to card partners',
    thiscategoryhasTeamMember:
      'This category cannot be deleted because it is attached to team member',
    hassubitems:
      'This project cannot be deleted because it has sub-projects attached to it, if you want to delete this project you have to delete sub-projects first',
  },
  'translations[0].Title': {
    required: 'Title field in English language is required',
  },
  'translations[1].Title': {
    required: 'Title field in Russian language is required',
  },
  'translations[2].Title': {
    required: 'Title field in Georgian language is required',
  },
  'translations[0].Slug': {
    required: 'Slug field in English language is required',
  },
  'translations[1].Slug': {
    required: 'Slug field in Russian language is required',
  },
  'translations[2].Slug': {
    required: 'Slug field in Georgian language is required',
  },
  'translations[0].Description': {
    required: 'Description field in English language is required',
  },
  'translations[1].Description': {
    required: 'Description field in Russian language is required',
  },
  'translations[2].Description': {
    required: 'Description field in Georgian language is required',
  },
  'translations[0].Description1': {
    required: 'Description 1 field in English language is required',
  },
  'translations[1].Description1': {
    required: 'Description 1 field in Russian language is required',
  },
  'translations[2].Description1': {
    required: 'Description 1 field in Georgian language is required',
  },
  'translations[0].Description2': {
    required: 'Description 2 field in English language is required',
  },
  'translations[1].Description2': {
    required: 'Description 2 field in Russian language is required',
  },
  'translations[2].Description2': {
    required: 'Description 2 field in Georgian language is required',
  },
  'translations[0].ShareDescription': {
    required: 'Share Description field in English language is required',
  },
  'translations[1].ShareDescription': {
    required: 'Share Description field in Russian language is required',
  },
  'translations[2].ShareDescription': {
    required: 'Share Description field in Georgian language is required',
  },
  'translations[0].Address': {
    required: 'Address field in English language is required',
  },
  'translations[1].Address': {
    required: 'Address field in Russian language is required',
  },
  'translations[2].Address': {
    required: 'Address field in Georgian language is required',
  },

  'translations[2].Name': {
    required: 'Name field in Georgian language is required',
  },
  'translations[2].Surname': {
    required: 'Surname field in Georgian language is required',
  },
  'translations[0].Image': {
    required: 'Image field in English language is required',
  },
  'translations[1].Image': {
    required: 'Image field in Russian language is required',
  },
  'translations[2].Image': {
    required: 'Image field in Georgian language is required',
  },
  'translations[2].CoverImage': {
    required: 'Cover Image field in Georgian language is required',
  },
  'translations[2].Thumbnail': {
    required: 'Thumbnail Image field in Georgian language is required',
  },
  'translations[0].Video': {
    required: 'Video field in English language is required',
  },
  'translations[1].Video': {
    required: 'Video field in Russian language is required',
  },
  'translations[2].Video': {
    required: 'Video field in Georgian language is required',
  },
  'translations[2].Video.Id': {
    required: 'Youtube Video Link Field in Georgian language is required',
  },
  'translations[0].ShareTitle': {
    required: 'Share Title field in English language is required',
  },
  'translations[1].ShareTitle': {
    required: 'Share Title field in Russian language is required',
  },
  'translations[2].ShareTitle': {
    required: 'Share Title field in Georgian language is required',
  },
  'translations[0].ShareImage': {
    required: 'Share Title field in English language is required',
  },
  'translations[0].CoverImage': {
    required: 'Mobile Cover Image field in English language is required',
  },
  'translations[0].CoverImageMobile': {
    required: 'Mobile Cover Image field in English language is required',
  },
  'translations[2].CoverImageMobile': {
    required: 'Mobile Cover Image field in Georgian language is required',
  },
  'translations[2].Logo': {
    required: 'Logo field in Georgian language is required',
  },
  'translations[0].Link.Href': {
    required: 'Web Link Url field in English language is required',
  },
  'translations[1].Link.Href': {
    required: 'Web Link Url field in Russian language is required',
  },
  'translations[2].Link.Href': {
    required: 'Web Link Url field in Georgian language is required',
    notvalid: 'Web Link Url is not valid',
  },
  'translations[0].Link1.Href': {
    required: 'Web Link Url 1 field in English language is required',
  },
  'translations[1].Link1.Href': {
    required: 'Web Link Url 1 field in Russian language is required',
  },
  'translations[2].Link1.Href': {
    required: 'Web Link Url 1 field in Georgian language is required',
    notvalid: 'Web Link Url 1 field is not valid',
  },
  'translations[0].Link2.Href': {
    required: 'Web Link Url 2 field in English language is required',
  },
  'translations[1].Link2.Href': {
    required: 'Web Link Url 2 field in Russian language is required',
  },
  'translations[2].Link2.Href': {
    required: 'Web Link Url 2 field in Georgian language is required',
    notvalid: 'Web Link Url 2 field is not valid',
  },
  'translations[2].WorkingHours1Title': {
    required: 'Working hours 1 Title field in Georgian language is required',
  },
  'translations[2].WorkingHours2Title': {
    required: 'Working hours 2 Title field in Georgian language is required',
  },

  cityId: {
    Thevaluenullisnotvalid: 'The city id is not chosen',
    required: 'City field is required',
  },
  districtId: {
    Thevaluenullisnotvalid: 'The District id is not chosen',
    required: 'District field is required',
  },
  buildingNumber: {
    required: 'Building number field is required',
    exist: 'Building number already exists',
  },
  constructionStatus: {
    required: 'Construction status field is required',
  },
  video: {
    id: {
      required: 'Video field is empty',
    },
  },
  'video.Id': {
    required: 'Video field is empty',
  },
  'video.Thumbnail': {
    required: 'Video thumbnail is required to enter video',
  },
  'video.Service': {
    required: 'Video type is required to enter video',
  },
  projectId: {
    AvaluefortheprojectIdparameterorpropertywasnotprovided:
      'You must select Building to enter form',
    required: 'Project field is required',
  },
  coverImage: {
    required: 'Cover image or Cover video field is required',
  },
  shareImage: {
    required: 'Share image field is required',
  },
  image: {
    required: 'Image field is required',
  },
  thumbnail: {
    required: 'Thumbnail image field is required',
  },
  roomsCount: {
    required: 'Number of Rooms field is required',
  },
  number: {
    exist: 'This Number already exists',
    flatexist: 'Flat with this number already exists',
    floorexist: 'Floor with this number already exists',
    required: 'Number field is required',
  },
  'progressGallery[0].Translations[2].Description': {
    required: 'Progress Gallery Description field is required',
  },
  'progressGallery[0].Gallery': {
    required: 'Progress Gallery field is required',
  },
  'items[0].Translations[2].Title1': {
    required: 'Title 1 first field is required',
  },
  'items[0].Translations[2].Title2': {
    required: 'Title 1 second field is required',
  },
  'items[1].Translations[2].Title1': {
    required: 'Title 2 first field is required',
  },
  'items[1].Translations[2].Title2': {
    required: 'Title 2 second field is required',
  },
  'items[2].Translations[2].Title1': {
    required: 'Title 3 first field is required',
  },
  'items[2].Translations[2].Title2': {
    required: 'Title 3 second field is required',
  },
  'items[3].Translations[2].Title1': {
    required: 'Title 4 first field is required',
  },
  'items[3].Translations[2].Title2': {
    required: 'Title 4 second field is required',
  },
  'items[4].Translations[2].Title1': {
    required: 'Title 5 first field is required',
  },
  'items[4].Translations[2].Title2': {
    required: 'Title 5 second field is required',
  },
  'items[5].Translations[2].Title1': {
    required: 'Title 6 first field is required',
  },
  'items[5].Translations[2].Title2': {
    required: 'Title 6 second field is required',
  },
  categoryIds: {
    required: 'Categories field is required',
  },
  tagIds: {
    required: 'Categories field is required',
  },
  flatIds: {
    required: 'Flats field is required',
  },
  file: {
    alreadyexist: 'This file already exists in File Manager',
  },
  gallery: {
    required: 'Gallery field is required',
    greaterthanorequalto: 'Gallery must have at least one image',
  },
  parentId: {
    itemwithlocalroutecannothassubitems:
      'Item with Predefined Path cannot have sub pages',
  },
  workingHours1Period: {
    required: 'Working Hours 1 field is required',
  },
  workingHours2Period: {
    required: 'Working Hours 2 field is required',
  },
  path: {
    mustbeempty: "You can't delete folder while files are still inside",
    notvalid: "Folder Name can't have space in between",
  },
  'coordinate.Latitude': {
    required: 'Latitude coordinates in Address field is required',
  },
  'coordinate.Longitude': {
    required: 'Longitude coordinates in Address field is required',
  },
  maxEnabledProp: { 'allowed.8': 'Maximum allowed prop is 8.' },
};
