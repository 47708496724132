const concatenate = (prefix) => (name) => prefix.concat("/", name);

const actionName = concatenate("base");
export const REMOVE_NOTIFICATION = actionName("REMOVE_NOTIFICATION");
export const ENQUEUE_NOTIFICATION = actionName("ENQUEUE_NOTIFICATION");

export const enqueueNotification = (notification) => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_NOTIFICATION,
    value: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};
export const removeNotification = (key) => ({
  type: REMOVE_NOTIFICATION,
  value: key,
});

export const setErrorMsg = (msg) => (dispatch) => {
  dispatch(
    enqueueNotification({
      message: msg,
      options: {
        variant: "error",
      },
    })
  );
};
export const setSuccessMsg = (msg) => (dispatch) => {
  dispatch(
    enqueueNotification({
      message: msg,
      options: {
        variant: "success",
      },
    })
  );
};
