/* eslint-disable no-param-reassign */
import { handleFetch } from '../handler';
import * as API from './fmApi.js';

const fixPath = (path) => {
  return `${path}`.replace(/\/\//g, '/');
};

export const getFileList = ({ path, page, limit, count, searchWord }) => {
  path = fixPath(path);
  return new Promise((resolve, reject) => {
    return API.list({ path, page, limit, count, searchWord })
      .then(handleFetch(resolve, reject).xthen)
      .catch(handleFetch(resolve, reject).xcatch);
  });
};

export const getDirectoryList = ({ path, page, limit, count, searchWord }) => {
  path = fixPath(path);
  return new Promise((resolve, reject) => {
    return API.directoryList({ path, page, limit, count, searchWord })
      .then(handleFetch(resolve, reject).xthen)
      .catch(handleFetch(resolve, reject).xcatch);
  });
};

export const createFolder = ({ path, folderName }) => {
  return new Promise((resolve, reject) => {
    return API.createFolder({ path, folderName })
      .then(handleFetch(resolve, reject).xthen)
      .catch(handleFetch(resolve, reject).xcatch);
  });
};

export const uploadFiles = ({ path, fileList }) => {
  return new Promise((resolve, reject) => {
    return Promise.all(API.uploadFiles({ path, fileList }))
      .then(handleFetch(resolve, reject).xsthen)
      .catch(handleFetch(resolve, reject).xcatch);
  });
};

export const cropImage = ({ path, options }) => {
  return new Promise((resolve, reject) => {
    return API.cropImage({ path, options })
      .then(handleFetch(resolve, reject).xthen)
      .catch(handleFetch(resolve, reject).xcatch);
  });
};

export const deleteFilesAndDirs = ({ path, list }) => {
  return new Promise((resolve, reject) => {
    return Promise.all(API.deleteFilesAndDirs({ path, list }))
      .then(handleFetch(resolve, reject).xsthen)
      .catch(handleFetch(resolve, reject).xcatch);
  });
};
