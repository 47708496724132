/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import generateErrorMsg from '@utility/generateErrorMsg';
import * as fmApiHandler from '../api/fm/fmApiHandler.js';
import { errorHandler, filemanagerErrorHandler } from '@services/Utils';

import { setErrorMsg } from './base.action';

const concatenate = (prefix) => (name) => prefix.concat('/', name);

const actionName = concatenate('fm');
export const SET_PAGE = actionName('SET_PAGE');
export const SET_FILES_COUNT = actionName('SET_FILES_COUNT');
export const SET_DIRECTORIES_COUNT = actionName('SET_DIRECTORIES_COUNT');
export const SET_FILE_LIST = actionName('SET_FILE_LIST');
export const SET_SELECTED_FILES = actionName('SET_SELECTED_FILES');
export const SET_LOADING = actionName('SET_LOADING');
export const SET_FILE_CONTENT_IMAGE_URL = actionName(
  'SET_FILE_CONTENT_IMAGE_URL'
);
export const SET_VISIBLE_DIALOG_CONTENT = actionName(
  'SET_VISIBLE_DIALOG_CONTENT'
);
export const SET_PATH = actionName('SET_PATH');
export const SET_SEARCH_PATH = actionName('SET_SEARCH_PATH');
export const TOGGLE_SELECTED_FILE = actionName('TOGGLE_SELECTED_FILE');
export const ENTER_TO_DIRECTORY = actionName('ENTER_TO_DIRECTORY');
export const ENTER_TO_DIRECTORY_SEARCH = actionName(
  'ENTER_TO_DIRECTORY_SEARCH'
);
export const SET_CONTEXT_MENU_VISIBLE = actionName('SET_CONTEXT_MENU_VISIBLE');
export const SET_CONTEXT_MENU_POSITION = actionName(
  'SET_CONTEXT_MENU_POSITION'
);
export const SET_FILE_CROPPER_IMAGE = actionName('SET_FILE_CROPPER_IMAGE');
export const SET_VISIBLE_DIALOG_CROPPER = actionName(
  'SET_VISIBLE_DIALOG_CROPPER'
);
export const SET_VISIBLE_DIALOG_CREATE_FOLDER = actionName(
  'SET_VISIBLE_DIALOG_CREATE_FOLDER'
);
export const SET_VISIBLE_DIALOG_UPLOAD_FILE = actionName(
  'SET_VISIBLE_DIALOG_UPLOAD_FILE'
);
export const SET_FILE_UPLOAD_LIST = actionName('SET_FILE_UPLOAD_LIST');
export const SET_FILE_UPLOAD_PROGRESS = actionName('SET_FILE_UPLOAD_PROGRESS');
export const SET_FILE_LIST_FILTER = actionName('SET_FILE_LIST_FILTER');

export const setFilesCount = (count) => {
  return {
    type: SET_FILES_COUNT,
    value: count,
  };
};

export const setDirectoriesCount = (count) => {
  return {
    type: SET_DIRECTORIES_COUNT,
    value: count,
  };
};

export const setFileList = (fileList) => {
  return {
    type: SET_FILE_LIST,
    value: fileList,
  };
};

export const setSelectedFiles = (files) => {
  return {
    type: SET_SELECTED_FILES,
    value: files,
  };
};

export const setLoading = (value) => {
  return {
    type: SET_LOADING,
    value,
  };
};

export const setPage = (value) => {
  return {
    type: SET_PAGE,
    value,
  };
};

export const setVisibleDialogContent = (visible) => {
  return {
    type: SET_VISIBLE_DIALOG_CONTENT,
    value: !!visible,
  };
};

export const setVisibleDialogCropper = (visible) => {
  return {
    type: SET_VISIBLE_DIALOG_CROPPER,
    value: !!visible,
  };
};

export const setVisibleDialogCreateFolder = (visible) => {
  return {
    type: SET_VISIBLE_DIALOG_CREATE_FOLDER,
    value: !!visible,
  };
};

export const setVisibleDialogUploadFile = (visible) => {
  return {
    type: SET_VISIBLE_DIALOG_UPLOAD_FILE,
    value: !!visible,
  };
};

export const setFileContentImageUrl = (fileUrl) => {
  return {
    type: SET_FILE_CONTENT_IMAGE_URL,
    value: fileUrl,
  };
};

export const setFileCropperImage = (fileUrl) => {
  return {
    type: SET_FILE_CROPPER_IMAGE,
    value: fileUrl,
  };
};

export const setFileUploadList = (files) => {
  return {
    type: SET_FILE_UPLOAD_LIST,
    value: files,
  };
};

export const setFileUploadProgress = (percentage) => {
  return {
    type: SET_FILE_UPLOAD_PROGRESS,
    value: percentage,
  };
};

export const setPath = (path) => {
  return {
    type: SET_PATH,
    value: path,
  };
};

export const setSearchPath = (path) => {
  return {
    type: SET_SEARCH_PATH,
    value: path,
  };
};

export const toggleSelectedFile = (file) => {
  return {
    type: TOGGLE_SELECTED_FILE,
    value: file,
  };
};

export const setFileListFilter = (search) => {
  return {
    type: SET_FILE_LIST_FILTER,
    value: search,
  };
};

export const setContextMenuVisible = (visible) => {
  return {
    type: SET_CONTEXT_MENU_VISIBLE,
    value: !!visible,
  };
};

export const setContextMenuPosition = (x, y) => {
  return {
    type: SET_CONTEXT_MENU_POSITION,
    value: [x, y],
  };
};

export const refreshFileList =
  (searchWord, passedPage) => (dispatch, getState) => {
    const { path, page, limit, folderLimit, filesCount, directoriesCount } =
      getState().fm;

    dispatch(setLoading(true));
    dispatch(setSelectedFiles([]));
    if (searchWord && passedPage) dispatch(setPage(passedPage));

    const keys = ['files', 'directories'];
    const map = new Map();
    map.set(keys[0], []);
    map.set(keys[1], []);

    Promise.all([
      fmApiHandler.getFileList({
        path: path.join('/'),
        page,
        limit,
        count: filesCount,
        searchWord,
      }),
      fmApiHandler.getDirectoryList({
        path: path.join('/'),
        page,
        limit: folderLimit,
        count: directoriesCount,
        searchWord,
      }),
    ])
      .then((res) => {
        const { data: files, meta: filesMeta } = res[0];
        const { data: directories, meta: directoriesMeta } = res[1];

        const filesWithTypes = files.map((file) => ({
          ...file,
          type: 'file',
        }));

        const directoriesWithTypes = directories.map((dir) => ({
          ...dir,
          type: 'dir',
        }));

        setTimeout(() => {
          dispatch(setLoading(false));
        }, 1000);

        dispatch(setFilesCount(filesMeta.total));
        dispatch(setDirectoriesCount(directoriesMeta.total));

        dispatch(setFileList(directoriesWithTypes.concat(filesWithTypes)));
      })
      .catch((err) => {
        dispatch(setFileList([]));
        dispatch(setErrorMsg(err.toString()));
        dispatch(setLoading(false));
      });
  };

export const changePage = (page, search) => (dispatch) => {
  dispatch(setPage(page));
  if (search) {
    dispatch(refreshFileList(search));
    return;
  }
  dispatch(refreshFileList());
};

export const enterToDirectory = (directory) => (dispatch) => {
  dispatch(setPage(0));
  dispatch({
    type: ENTER_TO_DIRECTORY,
    value: directory,
  });
  dispatch(refreshFileList());
};

export const createNewFolder = (folderName) => async (dispatch, getState) => {
  try {
    const { path } = getState().fm;

    await fmApiHandler.createFolder({ path: path.join('/'), folderName });

    dispatch(setVisibleDialogCreateFolder(false));
    dispatch(refreshFileList());
  } catch (err) {
    if (err.errors) {
      // err.errors.forEach((err) => errorHandler(err));
      err.errors.forEach((err) => filemanagerErrorHandler(err));
      filemanagerErrorHandler(err);
    } else {
      dispatch(setErrorMsg(err.toString()));
    }
    throw new Error(err);
  }
};

export const resetFileUploader = () => (dispatch) => {
  dispatch(setFileUploadProgress(0));
  dispatch(setVisibleDialogUploadFile(false));
  dispatch(setFileUploadList([]));
};

export const uploadFiles = (fileList) => async (dispatch, getState) => {
  const { path } = getState().fm;
  dispatch(setLoading(true));
  dispatch(setSelectedFiles([]));
  dispatch(setFileUploadProgress(50));
  try {
    await fmApiHandler.uploadFiles({ path: path.join('/'), fileList });
    dispatch(setFileUploadProgress(100));
    setTimeout(() => {
      dispatch(resetFileUploader());
    }, 300);
    dispatch(refreshFileList());
  } catch (err) {
    if (err.errors) {
      err.errors.forEach((err) => dispatch(generateErrorMsg(err)));
      errorHandler(err);
      filemanagerErrorHandler(err);
    } else {
      dispatch(setErrorMsg(err.toString()));
    }
    dispatch(setLoading(false));
  }
};

export const setFileContent = (fileUrl) => (dispatch) => {
  dispatch(setFileContentImageUrl(null));
  dispatch(setVisibleDialogContent(true));
  dispatch(setFileContentImageUrl(fileUrl));
};

export const setFileCropper = (file) => (dispatch) => {
  dispatch(setFileCropperImage(null));
  dispatch(setVisibleDialogCropper(true));
  dispatch(setFileCropperImage(file));
};

export const cropImage = (options) => (dispatch, getState) => {
  const { path } = getState().fm;

  dispatch(setLoading(true));

  fmApiHandler
    .cropImage({ path: path.join('/'), options })
    .then(() => {
      setTimeout(() => {
        dispatch(resetFileUploader());
      }, 300);
      dispatch(refreshFileList());
    })
    .catch((err) => {
      if (err.errors) {
        err.errors.forEach((err) => {
          dispatch(setErrorMsg(generateErrorMsg(err)));
          errorHandler(err);
          filemanagerErrorHandler(err);
        });
      } else {
        dispatch(setErrorMsg(err.toString()));
      }
      dispatch(setLoading(false));
    });
};

export const deleteFilesAndDirs = (list) => (dispatch, getState) => {
  const { path } = getState().fm;

  dispatch(setLoading(true));

  fmApiHandler
    .deleteFilesAndDirs({ path: path.join('/'), list })
    .then(() => {
      dispatch(refreshFileList());
    })
    .catch((err) => {
      if (err.errors) {
        err.errors.forEach((err) => {
          dispatch(setErrorMsg(generateErrorMsg(err)));
          errorHandler(err);
          filemanagerErrorHandler(err);
        });
      } else {
        dispatch(setErrorMsg(err.toString()));
      }
      dispatch(setLoading(false));
    });
};

export const enterToPreviousDirectory = () => (dispatch, getState) => {
  const { path } = getState().fm;
  dispatch(setPath(path.slice(0, -1)));
  dispatch(refreshFileList());
};

export const enterToPreviousDirectoryByIndex =
  (index) => (dispatch, getState) => {
    const { path } = getState().fm;
    const newPath = [...path].slice(0, ++index);
    dispatch(setPath(newPath));
    dispatch(refreshFileList());
  };

export const setSelectedFileFromLastTo = (lastFile) => (dispatch, getState) => {
  const { fileList, selectedFiles } = getState().fm;

  const lastPreviouslySelected = [...selectedFiles].pop();
  const lastPreviouslySelectedIndex = fileList.indexOf(
    fileList.find((f) => f.name === lastPreviouslySelected.name)
  );
  const lastSelectedIndex = fileList.indexOf(
    fileList.find((f) => f.name === lastFile.name)
  );

  let toAdd = [];
  if (lastSelectedIndex > lastPreviouslySelectedIndex) {
    toAdd = fileList.filter((index) => {
      return (
        fileList.indexOf(index) <= lastSelectedIndex &&
        fileList.indexOf(index) >= lastPreviouslySelectedIndex
      );
    });
  } else {
    toAdd = fileList.filter((index) => {
      return (
        fileList.indexOf(index) >= lastSelectedIndex &&
        fileList.indexOf(index) <= lastPreviouslySelectedIndex
      );
    });
  }
  dispatch(setSelectedFiles([...selectedFiles, ...toAdd]));
};

export const rightClickOnFile = (file) => (dispatch, getState) => {
  const { selectedFiles } = getState().fm;
  const isSelected =
    selectedFiles.indexOf(selectedFiles.find((f) => f.name === file.name)) !==
    -1;

  !isSelected && dispatch(setSelectedFiles([file]));
};
